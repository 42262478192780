import React from 'react';
import Article from 'components/help/helpArticle';
import Image from 'components/help/helpImage';
import img1 from 'img/help/recording-iframes/iframes-1.png';

const Content = () => {
  return (
    <div>
      <p>Unfortunately, we don’t support iframes.</p>
      <p>
        Because of browser security (
        <a
          href="https://en.wikipedia.org/wiki/Cross-site_scripting"
          target="_blank"
          rel="noopener noreferrer"
        >
          XSS
        </a>{' '}
        protection) measures, we can’t record iframes forwarding to another domain. When you watch a
        recording, the iframe is displayed as a greyed out element:
      </p>
      <Image src={img1} />
    </div>
  );
};

const related = [
  {
    link: '/help/how-livesession-script-works/',
    title: 'How LiveSession tracking script works',
    description: 'Understand what our tracking code tracks',
  },
];

export const frontmatter = {
  metaTitle: 'Does LiveSession record iframes?',
  metaDescription: 'Find more information about recording iframes.',
  canonical: '/help/does-livesession-record-iframes/',
};

const Wrapped = Article(Content);

export default () => (
  <Wrapped related={related} section="Product" title={frontmatter.metaTitle} {...frontmatter} />
);
